<template>
  <div class="page-body">
    <div class="page-body-content">
      <div class="selection-part-one">
        <titleBar title="云电池管理系统" :other="true"></titleBar>
        <div class="content">
          <p>
            云电池管理系统是简捷基于物联网、边缘计算、AI技术打造的新一代移动电池包管理的端到端解决方案，可为客户提供移动电池包全方位保护、资产管理、极简运维等服务。目前已在超过50万个电池包中得以应用。
          </p>
        </div>
        <div class="part-one-content">
          <div class="left-content">
            <div class="title">智慧服务云平台</div>
            <div class="image-content">
              <img src="https://cdn.lnxall.com/img11.png" alt="" srcset="" />
            </div>
            <div class="left-arrow">物联网接入</div>
            <img
              class="left-arrow-img"
              src="https://cdn.lnxall.com/img12.png"
              alt=""
              srcset=""
            />
          </div>
          <div class="right-content">
            <div class="title">边缘计算</div>
            <div class="content-box">
              <div class="item-list">
                <div class="content">
                  <img src="https://cdn.lnxall.com/mobile/img13.png" alt="" srcset="" />
                  <div class="subtitle">电池包</div>
                </div>
              </div>
              <div class="item-list">
                <div class="content">
                  <img src="https://cdn.lnxall.com/mobile/img14.png" alt="" srcset="" />
                  <div class="subtitle">二轮车</div>
                </div>
              </div>
              <div class="item-list">
                <div class="content">
                  <img src="https://cdn.lnxall.com/mobile/img15.png" alt="" srcset="" />
                  <div class="subtitle">车载后装</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-full-body-content">
      <div class="title-content">方案优势</div>
      <div class="leet-full-content">
        <div class="leet-body">
          <div class="leet-item">
            <div class="imagebox">
              <img src="https://cdn.lnxall.com/mobile/battery.png">
            </div>
            <div class="sub-title">安全保障</div>
            <div class="intro">
              温度、电压、电流检测与保护，电流检测与保护，电池均衡（主动均衡与被动均衡），高速响应短路保护
            </div>
          </div>
          <div class="leet-item">
            <div class="imagebox">
              <img src="https://cdn.lnxall.com/mobile/battery.png">
            </div>
            <div class="sub-title">超低损耗</div>
            <div class="intro">智能保护板支持超低功耗休眠及唤醒</div>
          </div>
          <div class="leet-item">
            <div class="imagebox">
              <img src="https://cdn.lnxall.com/mobile/battery.png">
            </div>
            <div class="sub-title">极速适配</div>
            <div class="intro">
              极速适配个大电池厂家的36V、48V、60V二轮车电池，一次性调试，永久使用
            </div>
          </div>
          <div class="leet-item">
            <div class="imagebox">
              <img src="https://cdn.lnxall.com/mobile/battery.png">
            </div>
            <div class="sub-title">资产保护</div>
            <div class="intro">
              极速寻包，通过实时定位、轨迹记录与回放、电池锁电等功能极速找包，防止资产流失
            </div>
          </div>
          <div class="leet-item">
            <div class="imagebox">
              <img src="https://cdn.lnxall.com/mobile/battery.png">
            </div>
            <div class="sub-title">极简运维</div>
            <div class="intro">
              可视化智能监控，电池包集中管理，极大降低运维成本
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="page-full-body-content"
      style="
        margin-top: 0;
        background: url('https://cdn.lnxall.com/img17.png') no-repeat;
        background-size: 100% 100%;
      "
    >
      <div class="leet-full-content">
        <div class="title-content" style="margin-top: 40px">方案价值</div>
        <div class="plane-content">
          <div class="plane-item">
            <div class="imagebox">
              <img src="https://cdn.lnxall.com/img18.png" alt="" srcset="" />
            </div>
            <div class="des">安全可靠</div>
          </div>
          <div class="plane-item">
            <div class="imagebox">
              <img src="https://cdn.lnxall.com/img19.png" alt="" srcset="" />
            </div>
            <div class="des">降本增效</div>
          </div>
          <div class="plane-item">
            <div class="imagebox">
              <img src="https://cdn.lnxall.com/img20.png" alt="" srcset="" />
            </div>
            <div class="des">极简运维</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import titleBar from "../../components/titleBar";
export default {
  name: "battery-page",
  components: {
    titleBar,
  },
  data() {
    return {
      active: 1,
      cardactive: true,
    };
  },
  mounted() {},
  filters: {},
  created() {},
  methods: {
    onTabChange(value) {
      this.active = value;
    },
  },
  destroyed() {},
};
</script>
<style scoped lang="less">
.page-body {
  box-sizing: border-box;
  background-color: #ffffff;
  .page-body-content {
    box-sizing: border-box;
    border: 1px solid transparent;
    .selection-part-one {
      padding: 15px;
      .content {
        p {
          overflow-wrap: break-word;
          color: #222222;
          font-size: 14px;
          line-height: 28px;
          text-align: left;
          margin-top: 10px;
        }
      }
      .part-one-content {
        display: flex;
        .left-content {
          width: 50%;
          position: relative;
          .title {
            display: inline-block;
            overflow-wrap: break-word;
            color: #222222;
            font-size: 14px;
            white-space: nowrap;
            text-align: left;
            position: relative;
          }
          .title::before {
            position: absolute;
            content: "";
            width: 24px;
            height: 2px;
            background-color: #247777;
            top: -10px;
            left: 0;
          }
          .image-content {
            position: absolute;
            top: 80px;
            width: 100%;
            height: 120px;
            img {
              width: 60%;
              object-fit: contain;
            }
          }
          .left-arrow {
            position: absolute;
            height: 24px;
            display: inline-block;
            overflow-wrap: break-word;
            color: #247777;
            font-size: 12px;
            white-space: nowrap;
            line-height: 28px;
            text-align: right;
            top: 100px;
            right: 20px;
          }
          .left-arrow-img {
            width: 27px;
            height: 21px;
            position: absolute;
            top: 103px;
            right: 0;
          }
        }
        .right-content {
          width: 50%;
          position: relative;
          .title {
            display: inline-block;
            overflow-wrap: break-word;
            color: #222222;
            font-size: 14px;
            white-space: nowrap;
            position: relative;
          }
          .title::before {
            position: absolute;
            content: "";
            width: 24px;
            height: 2px;
            background-color: #247777;
            top: -10px;
            left: 0;
          }
          .content-box {
            display: flex;
            margin-top: 25px;
            box-sizing: border-box;
            .item-list {
              width: 33.3%;
              box-sizing: border-box;
              height: 100px;
              display: flex;
              justify-content: center;
              .content {
                box-sizing: border-box;
                border-radius: 12px;
                position: relative;
                img {
                  width: 100%;
                }
                .subtitle {
                  text-align: center;
                  position: absolute;
                  bottom: 40px;
                  left: 0;
                  right: 0;
                  color: #222222;
                  font-size: 12px;
                  white-space: nowrap;
                }
              }
            }
          }
        }
      }
    }
  }
  .page-full-body-content {
    border: 1px solid transparent;
    background-color: #f4f8f8;
    margin-top: 10px;
    width: 100%;
    box-sizing: border-box;
    .title-content {
      text-align: center;
      color: #222222;
      font-size: 18px;
      font-family: PingFangSC-Medium;
      white-space: nowrap;
      margin-top: 20px;
    }
    .plane-content {
      display: flex;
      justify-content: space-around;
      padding-bottom: 40px;
      .plane-item {
        width: 33.3%;
        .imagebox {
          width: 100%;
          text-align: center;
          margin-top: 30px;
          img {
            width: 45px;
            height: 44px;
          }
        }
        .des {
          margin-top: 20px;
          text-align: center;
          color: #222222;
          font-size: 14px;
          font-family: PingFangSC-Medium;
          white-space: nowrap;
        }
      }
    }
    .leet-full-content {
      .leet-body {
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .leet-item {
          height: 160px;
          border-radius: 8px;
          box-shadow: 0px 16px 24px 0px rgb(183, 188, 205, 0.2);
          width: 90%;
          position: relative;
          margin-bottom: 20px;
          background-color: #fff;
          .imagebox{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 10px;
            img{
              width: 28px;
            }
          }
          .sub-title {
            width: 100%;
            text-align: center;
            height: 30px;
            color: #222222;
            font-size: 14px;
            font-family: PingFangSC-Medium;
            line-height: 30px;
            position: absolute;
            margin-top: 20px;
          }
          .intro {
            color: #555555;
            font-size: 12px;
            line-height: 22px;
            text-align: center;
            align-items: center;
            position: absolute;
            margin-top: 60px;
            padding: 0 20px;
          }
        }
      }
    }
  }
}
</style>
