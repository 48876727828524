<template>
  <div class="web-title-bar-page">
    <div class="title-bar-content">
      <div class="imgbox">
        <img v-if="other" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJQAAAASCAMAAACKCwcjAAAARVBMVEUAAAAleHgqeHgkeHgkd3cleHgjeHgjeHgkd3cld3cpe3sugIAjd3ckd3ckdnYldnYmeHgmd3coeXkoeXkkdnYmeXkkd3eJic9XAAAAFnRSTlMAvBxLgvNlMqqVEgnmzLOZcV45Jtx6Dr62VAAAAKBJREFUSMft1TkOxSAMBNAQOyzZ2Ln/UT9I36alJBLTvTQziqJ4u6KK1/bPFSB0gATG6bM/Gc64DtTI2I0wO0Mn3SEOMYyolIr0KABAIICUEggeET3BWWsdAd/3RYJ5nscQ9H3fmiBKKWIUqoV3tBBkCyEjYubqOqpX11G9uo7iglRHJcJR245BzPmmpvymVlZWVmbPlD/PKc/MOshfPsg/vlcW1XeXwfUAAAAASUVORK5CYII=" alt="" srcset="" />
        <img v-else src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJQAAAASCAMAAACKCwcjAAAASFBMVEUAAAAAU/8AVP8AVP8AUv8AUf8AU/8AUv8AU/8AUf8AUv8AUf8AUv8AU/8AUf8AUf8AVP8AVf8AUf8AUv8AUv8AUv8AVP8AUv9p+g20AAAAF3RSTlMAu0sdmILzFGUyqgvmzLNxXjkm3MOSevZUC9UAAAChSURBVEjH7dXJDoUgDIVhqa1CHRic3v9NLyb34NYlJvy7z40NIbSbkktT92+KFB+QpYIxnGEs8Id/ICoPeOeCXjftC8xgXiM55xI+RSKKAFlrCQgiEgDPzB6QdV0F4GVZGNB5nhUw13WZt3B3ZY47wN4Bp4icwMHMB6B5KAX2PNQObHmoDRjy34aXqPOkqrxTrVarVXtVPp5Vrpm2kL+8kH/orRd3ioU4vwAAAABJRU5ErkJggg==" alt="" srcset="" />
      </div>
      <div class="title">{{title}}</div>
      <div class="imgbox">
        <img v-if="other" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJQAAAASCAMAAACKCwcjAAAARVBMVEUAAAAleHgqeHgkeHgkd3cleHgjeHgjeHgkd3cld3cpe3sugIAjd3ckd3ckdnYldnYmeHgmd3coeXkoeXkkdnYmeXkkd3eJic9XAAAAFnRSTlMAvBxLgvNlMqqVEgnmzLOZcV45Jtx6Dr62VAAAAKFJREFUSMft1TkOxCAQRFFwt1m8stjc/6gD0hSkhFjiZ4+oZFkgFrnKRfzrhXlMg5W2YmPDW4WzrsG/vmInRXtFoNAQddyFTClJHHXCHMdhAHuepwX4ui4G3H3fDvDM7AFSShEQiCgAUWsdxZpSWnHUiSePegCZR7W5eVSbm0dZ4GXmF1AlgEqALo35pYb8p2az2Wz0hrw8h3xm5oP85Qf5B42XFtWuAOCSAAAAAElFTkSuQmCC" alt="" srcset="" />
        <img v-else src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJQAAAASCAMAAACKCwcjAAAASFBMVEUAAAAAU/8AVP8AVP8AUv8AUf8AU/8AUv8AU/8AUf8AUv8AUf8AUv8AU/8AUf8AUf8AVP8AVf8AUf8AUv8AUv8AUv8AVP8AUv9p+g20AAAAF3RSTlMAu0sdmILzFGUyqgvmzLNxXjkm3MOSevZUC9UAAACjSURBVEjH7dW5DsQgDEVR8NgJOAtLtv//0yHSPNKmZCRud2hiRQibjx3sx/x6C920YuSdxwpReRCO8CCesWIiR1NFovQg+zwZe12XxdFL6DzPCvCyLAzIuq4CBGYOQBSRCJBzjoBERAnI3vtshvK1AUcvsZWhNmAvQ+2AlqEUOJj5AE4ROQF3B9Ad4O/a/FNN3qler9drvSYfzybXTF/I/7yQvwTeF3f73+skAAAAAElFTkSuQmCC" alt="" srcset="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "title-bar",
  props: { title: String, other: Boolean },
  data() {
    return {};
  },
  mounted() {},
  filters: {},
  created() {},
  methods: {},
  destroyed() {},
};
</script>
<style scoped lang="less">
.web-title-bar-page {
  width: 100%;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  .title-bar-content {
    display: flex;
    .title {
      height: 36px;
      font-size: 18px;
      font-family: "PingFangSC-Medium, PingFang SC";
      font-weight: 500;
      color: #222222;
      line-height: 36px;
      margin: 0 20px;
    }
    .imgbox {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 60px;
        height: 7.2px;
      }
    }
  }
}
</style>
