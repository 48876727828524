<template>
  <div class="leet-carbon-page">
    <div class="page-header">
      <img src="https://cdn.lnxall.com/banner4.png" alt="" srcset="" />
      <img class="iconbg" src="https://cdn.lnxall.com/mobile/b4.png" alt="">
      <div class="title-box">
        <div class="title-content">
          <div class="top-title animate__animated animate__backInLeft">
            简捷数能
          </div>
          <div
            class="info-des animate__animated animate__backInLeft animate__delay-1s"
          >
            基于创新性的 <br /><span>物联网云边协同技术</span>
          </div>
        </div>
      </div>
    </div>
    <div class="leet-page-intro">
      <div class="intro">
        基于创新性的物联网云边协同技术，为客户提供储能（微网）电站的站点监控管理、分布式能源网络监控管理、动力移动电池包监控管理、分布式系统运维及数据分析、能源网络安全管理等方面的解决方案。
      </div>
    </div>
    <div class="leet-tab-bar">
      <div class="tab-bar-content">
        <div
          class="tab-item"
          :class="[active == 1 ? 'activetab' : '']"
          @click="onTabChange(1)"
        >
          站控系统
        </div>
        <div
          class="tab-item"
          :class="[active == 2 ? 'activetab' : '']"
          @click="onTabChange(2)"
        >
          云电池管理系统
        </div>
      </div>
    </div>
    <site v-if="active === 1"></site>
    <battery v-if="active === 2"></battery>
  </div>
</template>

<script>
import titleBar from "../../components/titleBar";
import site from "./site";
import battery from "./battery";
export default {
  name: "carbon-page",
  components: {
    titleBar,
    site,
    battery
  },
  data() {
    return {
      active: 1,
      cardactive: true,
    };
  },
  mounted() {},
  filters: {},
  created() {},
  methods: {
    onTabChange(value) {
      this.active = value;
    },
  },
  destroyed() {},
};
</script>
<style scoped lang="less">
.leet-carbon-page {
  box-sizing: border-box;
  .page-header {
    height: 260px;
    box-sizing: border-box;
    position: relative;
    background-color: #011315;
    .iconbg{
      position: absolute;
      z-index: 2;
      right: 20px;
      top: 100px;
      width: 120px;
      height: 68px;
    }
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    .title-box {
      position: absolute;
      left: 20px;
      right: 0;
      top: 60px;
      box-sizing: border-box;
      .title-content {
        margin: 0 auto;
        .top-title {
          height: 70px;
          color: #ffffff;
          font-size: 24px;
          font-family: PingFangSC-Medium;
          line-height: 70px;
        }
        .info-des {
          margin-top: 10px;
          font-size: 22px;
          font-family: PingFangSC-Light;
          color: #ffffff;
          line-height: 28px;
          letter-spacing: 1.2px;
          span {
            font-size: 24px;
            color: #ffffff;
            font-family: PingFangSC-Medium;
          }
        }
      }
    }
  }
  .leet-page-intro {
    border: 1px solid transparent;
    box-sizing: border-box;
    background-color: #ffffff;
    padding: 15px;
    .intro {
      margin: 0 auto;
      overflow-wrap: break-word;
      color: #222222;
      font-size: 14px;
      line-height: 28px;
      text-align: left;
      margin-top: 10px;
    }
  }
  .leet-tab-bar {
    height: 40px;
    padding: 0 20px;
    box-sizing: border-box;
    background-color: white;
    box-shadow: inset 0px -1px 1px 0px rgb(200, 200, 200,.5);
    .tab-bar-content {
      margin: 0 auto;
      height: 40px;
      line-height: 40px;
      display: flex;
      .tab-item {
        width: 50%;
        text-align: center;
        color: #555555;
        font-size: 16px;
        font-family: PingFangSC-Medium;
        cursor: pointer;
      }
      .activetab {
        color: #247777;
        border-bottom: 2px solid #247777;
      }
    }
  }
}
</style>
