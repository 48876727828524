var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-body"},[_c('div',{staticClass:"page-body-content"},[_c('div',{staticClass:"selection-part-one"},[_c('titleBar',{attrs:{"title":"站控系统","other":true}}),_c('div',{staticClass:"content"},[_vm._v(" SMC站控系统是简捷物联专为储能（微网）电站定制的新一代能量管理系统（EMS）。它既具备传统EMS的高时效、高稳定、高可靠等本地一体化系统的优势，又兼具云计算、平台化管理系统的大数据分析处理、设备远程监控、以及灵活、轻便、简单、快捷的部署维护能力。 ")])],1)]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-full-body-content",staticStyle:{"background-color":"#ffffff","position":"relative"}},[_c('div',{staticClass:"selection-part-two"},[_c('div',{staticClass:"section-title"},[_vm._v("产品优势")]),_c('div',{staticClass:"section-content"},[_c('div',{staticClass:"item-card-list card1"},[_c('img',{attrs:{"src":"https://cdn.lnxall.com/mobile/xtjk0.png"}}),_c('div',{staticClass:"card-title"},[_vm._v("系统监控")])]),_c('div',{staticClass:"item-card-list card1"},[_c('img',{attrs:{"src":"https://cdn.lnxall.com/mobile/nldd0.png"}}),_c('div',{staticClass:"card-title"},[_vm._v("能量调度")])]),_c('div',{staticClass:"item-card-list card1"},[_c('img',{attrs:{"src":"https://cdn.lnxall.com/mobile/sbgl0.png"}}),_c('div',{staticClass:"card-title"},[_vm._v("设备管理")])]),_c('div',{staticClass:"item-card-list card1"},[_c('img',{attrs:{"src":"https://cdn.lnxall.com/mobile/yxwh0.png"}}),_c('div',{staticClass:"card-title"},[_vm._v("运行维护")])]),_c('div',{staticClass:"item-card-list card1"},[_c('img',{attrs:{"src":"https://cdn.lnxall.com/mobile/sjfx0.png"}}),_c('div',{staticClass:"card-title"},[_vm._v("数据分析")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-full-body-content",staticStyle:{"background":"#f4f8f8"}},[_c('div',{staticClass:"selection-part-two"},[_c('div',{staticClass:"section-title",staticStyle:{"margin-top":"30px"}},[_vm._v("特点优势")]),_c('div',{staticClass:"leet-content-intro"},[_c('div',{staticClass:"image-content"},[_c('img',{attrs:{"src":"https://cdn.lnxall.com/img1.png","alt":""}})]),_c('div',{staticClass:"info-content",staticStyle:{"margin-top":"60px"}},[_c('div',{staticClass:"title-info"},[_vm._v(" 云边协同设计 "),_c('div',{staticClass:"left-top-icon"},[_c('img',{attrs:{"src":"https://cdn.lnxall.com/lefttop.png","alt":"","srcset":""}})]),_c('div',{staticClass:"right-top-icon leet1"},[_c('img',{attrs:{"src":"https://cdn.lnxall.com/rightbottom.png","alt":"","srcset":""}})])]),_c('div',{staticClass:"intro"},[_vm._v("便捷丰富的管理手段")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-full-body-content",staticStyle:{"background":"#ffffff"}},[_c('div',{staticClass:"selection-part-two",staticStyle:{"padding":"0 15px"}},[_c('div',{staticClass:"leet-content-intro",staticStyle:{"margin-top":"10px"}},[_c('div',{staticClass:"info-content",staticStyle:{"margin-top":"60px"}},[_c('div',{staticClass:"title-info"},[_vm._v(" 设备统一接入 "),_c('div',{staticClass:"left-top-icon"},[_c('img',{attrs:{"src":"https://cdn.lnxall.com/lefttop.png","alt":"","srcset":""}})]),_c('div',{staticClass:"right-top-icon leet2"},[_c('img',{attrs:{"src":"https://cdn.lnxall.com/rightbottom.png","alt":"","srcset":""}})])]),_c('div',{staticClass:"intro"},[_vm._v("数据全量监控，提升系统可维护性")])]),_c('div',{staticClass:"image-content"},[_c('img',{attrs:{"src":"https://cdn.lnxall.com/img2.png"}})])])]),_c('div',{staticClass:"page-full-image-bg",staticStyle:{"background":"url('https://cdn.lnxall.com/img3.png') no-repeat","background-size":"100% 100%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-full-body-content",staticStyle:{"background":"#f4f8f8"}},[_c('div',{staticClass:"selection-part-two",staticStyle:{"padding":"0 15px"}},[_c('div',{staticClass:"leet-content-intro",staticStyle:{"margin-top":"20px"}},[_c('div',{staticClass:"image-content"},[_c('img',{attrs:{"src":"https://cdn.lnxall.com/img4.png","alt":"","srcset":""}})]),_c('div',{staticClass:"info-content",staticStyle:{"margin-top":"60px"}},[_c('div',{staticClass:"title-info"},[_vm._v(" 智能策略管理 "),_c('div',{staticClass:"left-top-icon"},[_c('img',{attrs:{"src":"https://cdn.lnxall.com/lefttop.png","alt":"","srcset":""}})]),_c('div',{staticClass:"right-top-icon leet3"},[_c('img',{attrs:{"src":"https://cdn.lnxall.com/rightbottom.png","alt":"","srcset":""}})])]),_c('div',{staticClass:"intro"},[_vm._v("保障系统安全及收益最大化")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-full-body-content",staticStyle:{"background":"#ffffff"}},[_c('div',{staticClass:"selection-part-two",staticStyle:{"padding":"0 15px"}},[_c('div',{staticClass:"leet-content-intro",staticStyle:{"margin-top":"20px"}},[_c('div',{staticClass:"info-content",staticStyle:{"margin-top":"60px"}},[_c('div',{staticClass:"title-info"},[_vm._v(" 全周期多维度数据分析 "),_c('div',{staticClass:"left-top-icon"},[_c('img',{attrs:{"src":"https://cdn.lnxall.com/lefttop.png","alt":"","srcset":""}})]),_c('div',{staticClass:"right-top-icon leet4"},[_c('img',{attrs:{"src":"https://cdn.lnxall.com/rightbottom.png","alt":"","srcset":""}})])]),_c('div',{staticClass:"intro"},[_vm._v("系统智能巡检，提升系统安全性")])]),_c('div',{staticClass:"image-content"},[_c('img',{attrs:{"src":"https://cdn.lnxall.com/img5.png","alt":"","srcset":""}})])])]),_c('div',{staticClass:"page-full-image-bg",staticStyle:{"background":"url('https://cdn.lnxall.com/img6.png') no-repeat","background-size":"100% 100%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-full-body-content",staticStyle:{"background":"#f4f8f8"}},[_c('div',{staticClass:"selection-part-three"},[_c('div',{staticClass:"selection-three-content"},[_c('div',{staticClass:"item-list"},[_c('div',{staticClass:"imgbox"},[_c('img',{attrs:{"src":"https://cdn.lnxall.com/img7.png","alt":""}})]),_c('div',{staticClass:"content"},[_vm._v("开放式可编程设计，客户自由扩展定制")])]),_c('div',{staticClass:"item-list"},[_c('div',{staticClass:"imgbox"},[_c('img',{attrs:{"src":"https://cdn.lnxall.com/img8.png","alt":""}})]),_c('div',{staticClass:"content"},[_vm._v("一体式标准化结构设计，稳定可靠")])])]),_c('div',{staticClass:"selection-three-content"},[_c('div',{staticClass:"item-list"},[_c('div',{staticClass:"imgbox"},[_c('img',{attrs:{"src":"https://cdn.lnxall.com/img9.png","alt":""}})]),_c('div',{staticClass:"content"},[_vm._v("安全合规")])]),_c('div',{staticClass:"item-list"},[_c('div',{staticClass:"imgbox"},[_c('img',{attrs:{"src":"https://cdn.lnxall.com/img10.png","alt":""}})]),_c('div',{staticClass:"content"},[_vm._v(" 多种产品形态灵活支撑不同应用场景，"),_c('br'),_vm._v("简便易用 ")])])])])])
}]

export { render, staticRenderFns }